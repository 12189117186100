import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import Router from 'next/router'
import useUserData from 'hooks/useUserData'
import { useCollection } from '@sb-konzept/firebase-hooks'
import moment from 'moment'
import md5 from 'js-md5'

const Context = React.createContext()
export default Context

export function SubscriptionProvider({ children }) {
  const [products, setProducts] = useState({})
  const { value: user, ref, currentUser } = useUserData()
  const { value: samcart = [] } = useCollection(
    ref.collection('samcartSubscriptions').orderBy('expires', 'desc')
  )
  const receiptRequests = useRef({})
  const [valid, setValid] = useState(
    typeof window !== 'undefined' && window.cordova?.platformId !== 'ios'
  )
  useEffect(() => {
    if (ref?.id === '7xsjVrptxbczEkvfSPojfjiU9I63') {
      window.alert(`valid changed:  ${valid}`)
      try {
        if (valid) {
          window.alert(window.errors?.length)
          window.errors.map(msg => window.alert(msg))
        }
      } catch (e) {
        window.alert(e)
      }
    }
  }, [valid, ref?.id])
  const validateReceipt = useCallback(
    async receipt => {
      const hash = md5(receipt)
      if (!receiptRequests.current[hash] && currentUser) {
        const token = await currentUser.getIdToken()

        receiptRequests.current[hash] = fetch('/api/va', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            hash,
            receipt,
            token
          })
        }).then(res => res.json())
      }
      return receiptRequests.current[hash]
    },
    [currentUser]
  )
  const events = useRef([])
  const loadProducts = useCallback(() => {
    if (window.CdvPurchase && window.cordova.platformId === 'android') {
      window.store = window.CdvPurchase.store
      const products = [
        {
          id: 'monthly.com.how2beast.beastly',
          type: store.PAID_SUBSCRIPTION,
          platform: store.GOOGLE_PLAY
        },
        {
          id: 'yearly.com.how2beast.beastly',
          type: store.PAID_SUBSCRIPTION,
          platform: store.GOOGLE_PLAY
        }
      ]
      products.forEach(product => {
        if (!store.products.find(p => p.id === product.id))
          store.register(product)
        store.log.debug = (...params) =>
          events.current.push({ debugLog: params })
        events.current.push('added product')
        store.when(product.id).approved(p => {
          console.log(p, 'approved')
          events.current.push({ p, f: 'approved' })
          p.verify()
        })
        store.when(product.id).productUpdated(p => {
          console.log(p, 'updated', p.title)
          events.current.push({ p, f: 'updated' })
          setProducts(prev =>
            prev.loaded && !p.loaded ? prev : { ...prev, [p.id]: p }
          )
        })
        store.when(product.id).verified(p => {
          console.log(p, 'verified')
          events.current.push({ p, f: 'verified' })
          p.finish()
        })
      })
      // store.ready(true)
      store.initialize().then(() => {
        setProducts(prev =>
          store.products.reduce(
            (acc, p) => ({
              ...acc,
              [p.id]: p
            }),
            {}
          )
        )
      })

      return
    }
    if (window.store && ref?.id) {
      if (window.cordova.platformId === 'ios') {
        window.store.validator = async (product, callback) => {
          try {
            if (product.transaction) {
              const result = await validateReceipt(
                product.transaction.appStoreReceipt
              )
              if (result?.latest_receipt_info?.[0]?.expires_date_ms) {
                const v =
                  result.latest_receipt_info[0].expires_date_ms > Date.now()
                // if (ref.id === '7xsjVrptxbczEkvfSPojfjiU9I63') {
                //   window.alert(
                //     `${v ? 'valid' : 'invalid'} ${valid ? 'valid' : 'invalid'}`
                //   )
                // }
                setValid(prev => prev || v)
                return callback(
                  true,
                  v
                    ? { transaction: 'valid' }
                    : {
                        latest_receipt: true,
                        code: window.store.PURCHASE_EXPIRED,
                        error: { message: 'Purchase Expired' }
                      }
                )
              }
            }
          } catch (e) {
            if (ref.id === '7xsjVrptxbczEkvfSPojfjiU9I63') {
              window.alert(e.message)
            }
            if (ref?.id) {
              setValid(true)
            }
            window.errors = window.errors || []
            window.errors.push(e.message)
            console.log(e)
          }
          if (ref.id === '7xsjVrptxbczEkvfSPojfjiU9I63') {
            window.alert('fallback')
          }
          //
          return callback(true, { transaction: 'valid' })
        }
      }
      const products = [
        {
          id: 'monthly.com.how2beast.beastly',
          type: store.PAID_SUBSCRIPTION
        },
        {
          id: 'yearly.com.how2beast.beastly',
          type: store.PAID_SUBSCRIPTION
        }
      ]
      products.forEach(product => {
        if (!store.products.find(p => p.id === product.id))
          store.register(product)
        store.log.debug = (...params) =>
          events.current.push({ debugLog: params })
        events.current.push('added product')
        store.when(product.id).approved(p => {
          console.log(p, 'approved')
          events.current.push({ p, f: 'approved' })
          p.verify()
        })
        store.when(product.id).loaded(p => {
          console.log(p, 'loaded')
          events.current.push({ p, f: 'loaded' })
          setProducts(prev => ({ ...prev, [p.id]: p }))
        })
        store.when(product.id).updated(p => {
          console.log(p, 'updated', p.title)
          events.current.push({ p, f: 'updated' })
          setProducts(prev =>
            prev.loaded && !p.loaded ? prev : { ...prev, [p.id]: p }
          )
        })
        store.when(product.id).verified(p => {
          console.log(p, 'verified')
          events.current.push({ p, f: 'verified' })
          p.finish()
        })
        store.get(product.id)
      })
      // store.ready(true)
      store.refresh()
    }
  }, [setProducts, ref?.id])
  useEffect(() => {
    window.loadProducts = loadProducts
    document.addEventListener('deviceready', loadProducts)
    return () => document.removeEventListener('deviceready', loadProducts)
  }, [loadProducts])
  // useEffect(() => {
  //   if (user) {
  //     const data = user.badges?.subscriptionStreak || {}
  //     const dateString = moment().format('YYYY-MM-DD')
  //     const
  //   }
  // }, [user, storeSub, samcartSub])

  const [init, setInit] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setInit(false)
    }, 20000)
  }, [])

  const value = useMemo(() => {
    // DEPRECATED
    //const samcartSub =
    //samcart.length &&
    //(moment().isBefore(samcart[0].expires.toDate()) ||
    //samcart.find(
    //(sc) => sc.product?.id === 383959 || sc.product?.id === 383987
    //)) &&
    //(Object.values(products).find((p) =>
    //p.id.includes(samcart[0].interval)
    //) ||
    //true)

    // Get all BEASTLY samcart subscriptions that have a valid BEASTLY subscription ID and have not expired
    const SAMCART_BEASTLY_PRODUCT_IDS = [
      383959,
      470115,
      545144,
      383987,
      470125,
      545146,
      344503,
      342519,
      350904,
      360018,
      439676,
      433796,
      342279,
      342950,
      360013,
      439680,
      342948,
      934790,
      934801,
      934793
    ]
    const validSamcartSubs = samcart.filter(
      samcartSub =>
        moment().isBefore(samcartSub.expires.toDate()) &&
        SAMCART_BEASTLY_PRODUCT_IDS.includes(samcartSub.product?.id)
    )
    const samcartSub = validSamcartSubs.length > 0
    const storeSub = valid && Object.values(products).find(p => p.owned)

    const getSamcartBeastnationSub = () => {
      const SAMCART_BEASTNATION_PRODUCT_IDS = [
        835560,
        837683,
        835628,
        837684,
        837441
      ]

      // find the samcart subscription that is active and has not expired
      // with a product id that is one of the beastnation product ids
      const scProduct = samcart.find(
        sc =>
          SAMCART_BEASTNATION_PRODUCT_IDS.includes(sc.product?.id) &&
          moment().isBefore(sc.expires.toDate())
      )

      // if there is a product, return the native store product with the same interval
      // or just true if there is no native store product
      const sub =
        scProduct &&
        (Object.values(products).find(p => p.id.includes(scProduct.interval)) ||
          true)

      // console.debug('getSamcartBeastnationSub', scProduct, sub, products)

      return sub
    }

    const samcartBeastnationSub = getSamcartBeastnationSub()

    // Evaluate subscription tier
    function getSubscriptionTier(samcartSub, storeSub, samcartBeastnationSub) {
      if (storeSub || samcartSub) {
        return 'full'
      } else if (samcartBeastnationSub) {
        return 'beastnation'
      } else {
        return null
      }
    }

    return {
      id: samcartSub ? validSamcartSubs[0].id : storeSub?.id,
      products,
      //platform: storeSub ? 'native' : 'samcart', // its possible to have beastnation sub from samcart and full sub from native, native is prioritized
      platform: storeSub ? 'native' : samcartSub ? 'samcart' : 'native',
      subscribed: init || samcartSub || storeSub || samcartBeastnationSub,
      // default subscription tier is full, if no subscription is found we lock the content anyways
      // beastnation tier is for users who have a beastnation subscription which unlock just one course
      // subscriptionTier:
      //!storeSub && samcartBeastnationSub ? 'beastnation' : 'full',
      subscriptionTier: getSubscriptionTier(
        samcartSub,
        storeSub,
        samcartBeastnationSub
      ),
      refresh: () => {
        if (window.store && !Object.keys(products).length) {
          return loadProducts()
        }
        if (!window.store) {
          return // window.location.reload()
        }
        return (store.refresh || store.update)()
      },
      hadReceipt: Object.values(receiptRequests.current).length,
      isFritz: ref.id === '7xsjVrptxbczEkvfSPojfjiU9I63',
      manage: bool =>
        bool || !samcartSub
          ? store.manageSubscriptions()
          : Router.push('/contact'),
      order: id => {
        if (window.CdvPurchase && window.cordova.platformId === 'android') {
          return store.products.find(p => p.id === id)?.offers?.[0]?.order?.()
        }
        return store.order(id)
      },
      sentryLog: async bool => {
        await ref.collection('subscriptionLogs').add(
          JSON.parse(
            JSON.stringify({
              events: events.current,
              storeSub: storeSub || null,
              samcartSub: samcartSub || null,
              products,
              fromButton: !bool
            })
          )
        )
        if (!bool) window.alert('report sent')
      }
    }
  }, [products, samcart, ref?.id, init])
  return <Context.Provider value={value}>{children}</Context.Provider>
}
